import {assertIsVElement, h, updateElement, VElement, VNode} from '../lib/dvdi';
import {pageHeader, pageFooter} from "../lib/page";
import {navigateEvent} from '../app';

let homePageVElement: (VElement | null) = null;
let homePageIntervalId: number;

const homePageIterations: string[] = [
    'product management',
    'software development',
    'quality assurance'
];

let _homePageIter: number = 0;

function homePageTick(): void {
    _homePageIter += 1;
    if (_homePageIter >= homePageIterations.length) {
        _homePageIter = 0;
    }

    homePageUpdate();
}

function homePageUpdate() {
    if (homePageVElement === null) {
        return;
    }

    assertIsVElement(homePageVElement);
    if (homePageVElement.parentVNode === null) {
        return;
    }

    const parentElem = (homePageVElement.parentVNode as VElement).domElement;
    if (parentElem === null) {
        return;
    }

    if (homePageVElement.domElement === null) {
        return;
    }

    const index = Array.from(parentElem.childNodes).indexOf(homePageVElement.domElement);
    const newVElement = homePageComponent(_homePageIter);
    newVElement.parentVNode = homePageVElement.parentVNode;
    updateElement(
        parentElem, parentElem.childNodes[index], homePageVElement.parentVNode as VElement,
        homePageVElement, newVElement
    );
    homePageVElement = newVElement;
}

function homePageComponent(homePageIter: number): VElement {
    let contents: VElement =
        h('main', {className: 'main'},
            h('header', {className: 'banner'},
                h('div', {className: 'container'},
                    h('h1', {}, 'AI First development'),
                    h('p', {},
                        'Reimagine your approach to ',
                        h('span', {className: 'carousel'}, homePageIterations[homePageIter])
                    )
                )
            ),
            h('main', {className: 'content'},
                h('div', {className: 'container'},
                    h('section', {},
                        h('h2', {}, 'AI First: build your software differently'),
                        h('p', {},
                            'Building software used to be complex, time consuming, and incredibly expensive.  We\'re obsessed ' +
                            'with making it simpler, faster, and cheaper.'
                        ),
                        h('p', {},
                            'Our AI First tools let you put AI at the core of every part of your software development ' +
                            'journey.  We offer you a way to have AI help continually refine your vision, build your code, ' +
                            'generate your tests, and write your docs.'
                        ),
                        h('h2', {}, 'Metaphor'),
                        h('p', {},
                            'AI tools can\'t do magic.  Just like people, they need a lot of detail (context) to do exactly ' +
                            'what you want.  Metaphor is a simple but powerful language lets you combine all the expert ' +
                            'knowledge of your team and AI agents to build up all that detail, refine it, and then put it ' +
                            'to use.'
                        ),
                        h('p', {},
                            'While getting you started is great, software development doesn\'t stop with the first version.  ' +
                            'By focusing on what you want your software to do rather than on incremental changes, Metaphor ' +
                            'allows AI tools to help you throughout your software\'s lifecycle.  It can help you add features, ' +
                            'remove capabilities you no longer want, or upgrade package dependencies, or even help you build ' +
                            'an entirely new implementation!'
                        ),
                        h('p', {},
                            'Metaphor has been designed to work with different AI models so you can choose the model that ' +
                            'offers the right capabilities and costs for your needs.'
                        ),
                        h('div', {className: 'figure-pair'},
                            h('figure', {},
                                h('img', {
                                    src: '/home/claude-screenshot.webp',
                                    alt: 'A screenshot of Claude working with Metaphor.',
                                    width: 752,
                                    height: 'auto'
                                })
                            ),
                            h('figure', {},
                                h('img', {
                                    src: '/home/chatgpt-screenshot.webp',
                                    alt: 'A screenshot of ChatGPT working with Metaphor',
                                    width: 490,
                                    height: 'auto'
                                })
                            )
                        ),
                        h('h2', {}, 'Get the software and start building!'),
                        h('p', {},
                            'The Metaphor prompt compiler is open source and available under and Apache 2.0 license.  You ' +
                            'can find it on GitHub: ',
                            h('a', {
                                    href: 'https://github.com/m6r-ai/m6rc',
                                    target: '_blank',
                                    title: 'M6RC git repository on GitHub'
                                },
                                'github.com/m6r-ai/m6rc'
                            )
                        ),
                        h('p', {},
                            'Take a look at our ',
                            h('a', {
                                    href: '/blog',
                                    onclick: (e: MouseEvent) => navigateEvent(e, '/blog')
                                },
                                'M6R blog'
                            ),
                            ' to see some of what we\'ve been doing with Metaphor, and visit our ',
                            h('a', {
                                    href: '/metaphor',
                                    onclick: (e: MouseEvent) => navigateEvent(e, '/metaphor')
                                },
                                'Metaphor home page.'
                            )
                        )
                    )
                )
            ),
            h('footer', {className: 'trailer'})
        );

    contents.mountCallback = () => {
        homePageVElement = contents;
        homePageIntervalId = window.setInterval(homePageTick, 2000);
    }

    contents.unmountCallback = () => {
        window.clearInterval(homePageIntervalId);
        homePageVElement = null;
    }

    return contents;
}

export function homePage(): VNode {
    return h('div', {className: 'app'},
        pageHeader(),
        homePageComponent(_homePageIter),
        pageFooter()
    );
}
