import {h, VNode} from '../lib/dvdi';
import {hero} from '../components/hero';
import {pageFooter} from "../components/pageFooter";
import {pageHeader} from "../components/pageHeader";
import {navigateEvent} from '../app';
import {imageCarousel} from '../components/carousel';
import {downloadComponent} from '../components/download';

export function humbugPage(): VNode {
    // Define the carousel images
    const carouselImages = [
        {
            src: '/humbug/humbug-v0.9-1.webp',
            alt: 'Humbug in action',
            width: '1280',
            height: 'auto'
        },
        {
            src: '/humbug/humbug-v0.9-2.webp',
            alt: 'Humbug in action',
            width: '1280',
            height: 'auto'
        },
        {
            src: '/humbug/humbug-v0.9-3.webp',
            alt: 'Humbug in action',
            width: '1280',
            height: 'auto'
        },
        {
            src: '/humbug/humbug-v0.9-4.webp',
            alt: 'Humbug in action',
            width: '1280',
            height: 'auto'
        },
        {
            src: '/humbug/humbug-v0.9-5.webp',
            alt: 'Humbug in action',
            width: '1280',
            height: 'auto'
        }
    ];

    return h('div', {className: 'app'},
        pageHeader(),
        h('main', {className: 'main'},
            hero({
                title: 'Humbug',
                subtitle: 'AI-first tooling for your whole software lifecycle'
            }),
            h('main', {className: 'content'},
                h('div', {className: 'container'},
                    h('article', {},
                        h('section', {},
                            h('h2', {}, 'Go beyond code completions - engineer software with AI'),
                            h('p', {},
                                'AI-powered app builders can turn ideas into demos.  AI-powered IDEs can help you add new ' +
                                'features at unprecedented speeds.  But what happens after that?  How do you maintain and ' +
                                'evolve things, how do you deal with bugs and vulnerabilites, how do you add the next big ' +
                                'feature and not break existing ones?  The problem is, once you\'ve finished those things ' +
                                'the AI has no idea what you were trying to do, or why, so its ability to help reduces ' +
                                'over time.'
                            ),
                            h('p', {},
                                'Humbug is an extensible, open-source, tool designed to do things differently.'
                            ),
                            h('p', {},
                                    'At its core is ',
                                    h('a', {
                                        href: '/metaphor',
                                        onclick: (e: MouseEvent) => navigateEvent(e, '/metaphor')
                                    },
                                    'Metaphor'
                                ),
                                ', an open source prompt creation language.  ' +
                                'Metaphor lets you capture what you want your software to do and all the information (context) ' +
                                'an AI will need to help you develop and support that software throughout its lifecycle.'
                            ),
                            h('p', {},
                                'Metaphor lets you get to the "engineering" in prompt engineering.  It helps massively ' +
                                'increase the work that can be done by AI, and significantly reduces rates of hallucination ' +
                                'by ensuring the AI doesn\'t have to guess what you want to build.'
                            ),
                            h('figure', {},
                                imageCarousel(carouselImages, 6000, 'humbug-carousel'),
                            ),
                            h('p', {},
                                'Humbug uses a familiar IDE-style environment but it\'s built to have AI at its core.  You can ' +
                                'discuss your software, seek recommendations, get help designing and coding new and complex ' +
                                'capabilities, or use it to understand and navigate what you have.'
                            ),
                            h('p', {},
                                'You can find out more on our ',
                                h('a', {
                                    href: 'https://youtube.com/@m6rai',
                                    target: '_blank',
                                    title: 'm6rai YouTube channel'
                                },
                                'YouTube'
                            ),
                            ' channel.'
                            )
                        ),
                        h('section', {},
                            h('h2', {}, 'Open and Extensible'),
                            h('p', {},
                                'We believe in the power of using AI in a different way, so Humbug is open-source, and that ' +
                                'includes the Metaphor description we use to build and extend it.  By doing this we\'ve made ' +
                                'it easy to extend and our aim to to ensure it will always provide the best possible ' +
                                'experience when working with AI.'
                            )
                        ),
                        h('section', {},
                            h('h2', {}, 'Capabilities'),
                            h('ul', {},
                                h('li', {},
                                    'Integrated Metaphor compiler: Seamlessly integrate with the ',
                                    h('a', {
                                            href: '/metaphor',
                                            onclick: (e: MouseEvent) => navigateEvent(e, '/metaphor')
                                        },
                                        'Metaphor'
                                    ),
                                    ' language to let you structure complex tasks to be solved by AI.'
                                ),
                                h('li', {}, 'Multi-AI interaction: Interact with multiple AI models simultaneously'),
                                h('li', {},
                                    'Wide range of AI models: Use AI models from Anthropic, DeepSeek, Google, ' +
                                    'Mistral, Ollama, and OpenAI'
                                ),
                                h('li', {},
                                    'Visible AI reasoning: See reasoning support from Anthropic and DeepSeek AI ' +
                                    'models that support it'
                                ),
                                h('li', {}, 'Cross-platform: Runs on MacOs, Windows, and Linux'),
                                h('li', {}, 'IDE-style file editing: Edit files directly within the platform'),
                                h('li', {},
                                    'Polyglot language support: Supports C, C++, CSS, Go, HTML, Java, JavaScript, JSON, ' +
                                    'Kotlin, Metaphor, Move, Python, Rust, Scheme, Swift, and TypeScript'
                                ),
                                h('li', {}, 'Command line integration: Use command line utilities to help you')
                            )
                        ),
                        downloadComponent(true, false),
                        h('section', {},
                            h('h2', {}, 'Frequently asked questions'),
                            h('p', {},
                                'Have questions about Humbug? Visit our ',
                                h('a', {
                                        href: '/faq#humbug',
                                        onclick: (e: MouseEvent) => navigateEvent(e, '/faq#humbug')
                                    },
                                    'FAQ page'
                                ),
                                ' to find answers to common questions about installation, supported platforms, AI models, and more.'
                            )
                        ),
                        h('section', {},
                            h('h2', {}, 'Using Humbug with Metaphor'),
                            h('p', {},
                                'A tutorial showing how to use Metaphor within Humbug is available on GitHub: ',
                                h('a', {
                                        href: 'https://github.com/m6r-ai/hello-metaphor',
                                        target: '_blank',
                                        title: 'hello-metaphor git repository on GitHub'
                                    },
                                    'github.com/m6r-ai/hello-metaphor'
                                )
                            )
                        ),
                        h('section', {},
                            h('h2', {}, 'View our YouTube channel'),
                            h('p', {},
                                'You can find out more on our ',
                                h('a', {
                                        href: 'https://youtube.com/@m6rai',
                                        target: '_blank',
                                        title: 'm6rai YouTube channel'
                                    },
                                    'YouTube'
                                ),
                                ' channel.'
                            )
                        ),
                        h('section', {},
                            h('h2', {}, 'Connect with us'),
                            h('p', {},
                                'Join us on ',
                                h('a', {href: 'https://discord.gg/GZhJ7ZtgwN', target: '_blank', title: 'Discord'},
                                    'Discord'
                                ),
                                ', or follow us on ',
                                h('a', {href: 'https://linkedin.com/company/m6r/', target: '_blank', title: 'LinkedIn'},
                                    'LinkedIn'
                                ),
                                ', to find out more about how Humbug can help you.'
                            )
                        )
                    )
                )
            )
        ),
        pageFooter()
    );
}
