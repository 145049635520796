import {h, VNode} from '../lib/dvdi';
import {navigateEvent} from '../app';
import {pageHeader, pageFooter} from "../lib/page";

export function metaphorPage(): VNode {
    return h('div', {className: 'app'},
        pageHeader(),
        h('main', {className: 'main'},
            h('header', {className: 'banner'},
                h('div', {className: 'container'},
                    h('h1', {}, 'Metaphor'),
                    h('p', {},
                        'A language designed for building software with AI'
                    )
                )
            ),
            h('main', {className: 'content'},
                h('div', {className: 'container'},
                    h('article', {},
                        h('section', {},
                            h('h2', {}, 'What is Metaphor?'),
                            h('p', {},
                                'Metaphor is a simple language designed to help you focus on your goals when building software.  ' +
                                'It provides a framework for all your key contributors to create a single shared description, ' +
                                'helping to capture their expertise. AI agents can then pick up from there.'
                            )
                        ),
                        h('section', {},
                            h('h2', {}, 'What makes Metaphor different?'),
                            h('p', {},
                                'Conventional software development creates islands of disconnected information.  Product ' +
                                'requirements, engineering implementation details, testing, security, and deployment are often ' +
                                'captured in different ways.  Left unchecked, ambiguous or inconsistent elements can find their ' +
                                'way into implementations, confusing everyone and leading to high costs to correct them.'
                            ),
                            h('p', {},
                                'Worse, conventional software development tends to focus on a series of successive changes.  ' +
                                'Even if we start with good intentions, we rarely maintain the top-level view and tend to lose ' +
                                'sight of the bigger picture over time.'
                            ),
                            h('p', {},
                                'Metaphor encourages you to work at a higher level.  It\'s designed to let you focus ' +
                                'on what you want your software to do, not how it\'s built.  As new requirements and ' +
                                'capabilities are needed, they can be captured in the Metaphor description of your software and ' +
                                'checked to ensure they align with previous elements.  Once you have a finished description, it ' +
                                'can be passed to AI agents to help deliver the implementation, including any tests and ' +
                                'documentation you might need.'
                            ),
                            h('p', {},
                                'By focusing on what you want and why, your investment is also future-proofed.  It can help you ' +
                                'maintain and modify what you build.  And, with each new generation of AI agent, you can use ' +
                                'their enhanced capabilities to help you continually build better software.'
                            )
                        ),
                        h('section', {},
                            h('h2', {}, 'Iterate quickly'),
                            h('p', {},
                                'As AI agents do implementation work rapidly, trying new ideas and discarding ones you don\'t ' +
                                'like is easy.  This means you can afford to try a few different approaches if you\'re unsure ' +
                                'which one might work best rather than settling for the first one you develop.'
                            ),
                            h('p', {},
                                'Like conventional software development, Metaphor encourages teams to use version control and ' +
                                'careful review before accepting changes.  This also helps keep everyone aligned and ' +
                                'dramatically improves time to value creation.'
                            )
                        ),
                        h('section', {},
                            h('h2', {}, 'Latest releases'),
                            h('p', {},
                                'You can find the latest release of the stand-alone Metaphor compiler on GitHub: ',
                                h('a', {
                                        href: 'https://github.com/m6r-ai/m6rc',
                                        target: '_blank',
                                        title: 'm6rc git repository on GitHub'
                                    },
                                    'github.com/m6r-ai/m6rc'
                                )
                             ),
                             h('p', {},
                                'You can also find the latest release of the Metaphor parser and prompt compiler library on ' +
                                'GitHub too.  This is a Python library that generates large context prompts, making it easy ' +
                                'to embed complex prompting behaviour into your AI-powered applications.  We use this as the ' +
                                'core engine for many of our tools: ',
                                h('a', {
                                        href: 'https://github.com/m6r-ai/m6rclib',
                                        target: '_blank',
                                        title: 'm6rclib git repository on GitHub'
                                    },
                                    'github.com/m6r-ai/m6rclib'
                                )
                            )
                        ),
                        h('section', {},
                            h('h2', {}, 'Examples'),
                            h('p', {},
                                'You can find examples powered by Metaphor on GitHub.  Some of these are demos we\'ve written, ' +
                                'and some are tools we use to help us build our software!',
                                h('ul', {},
                                    h('li', {},
                                        h('a', {
                                                href: 'https://github.com/m6r-ai/commit-critic',
                                                target: '_blank',
                                                title: 'code-review2 git repo on GitHub'
                                            },
                                            'github.com/m6r-ai/commit-critic'
                                        ),
                                        ' - a source code reviewing application written using m6rc and that also uses m6rclib.  ' +
                                        'Read more about this at: ',
                                        h('a', {
                                                href: '/blog/000005-commit-critic',
                                                onclick: (e: MouseEvent) => navigateEvent(
                                                    e, '/blog/000005-commit-critic'
                                                )
                                            },
                                            'commit-critic: An AI-powered, AI-built code review tool'
                                        ),
                                    ),
                                    h('li', {},
                                        h('a', {
                                                href: 'https://github.com/m6r-ai/demo-blog-editor',
                                                target: '_blank',
                                                title: 'demo-blog-editor git repo on GitHub'
                                            },
                                            'github.com/m6r-ai/demo-blog-editor'
                                        ),
                                        ' - an example of using m6rc to convert a markdown file (a blog post) into TypeScript ' +
                                        'code that uses a custom HTML format.'
                                    ),
                                    h('li', {},
                                        h('a', {
                                                href: 'https://github.com/m6r-ai/demo-code-review',
                                                target: '_blank',
                                                title: 'demo-code-review git repo on GitHub'
                                            },
                                            'github.com/m6r-ai/demo-code-review'
                                        ),
                                        ' - an example of using m6rc to set up a review of Python or TypeScript source code.  ' +
                                        'Read more about this at: ',
                                        h('a', {
                                                href: '/blog/000004-Code-reviews-using-Metaphor',
                                                onclick: (e: MouseEvent) => navigateEvent(
                                                    e, '/blog/000004-Code-reviews-using-Metaphor'
                                                )
                                            },
                                            'Code reviews using Metaphor'
                                        )
                                    ),
                                )
                            ),
                        ),
                        h('section', {},
                            h('h2', {}, 'Discussion forum'),
                            h('p', {},
                                'If you\'d like to discuss Metaphor and it\'s uses, we have a metaphor-language discussion ' +
                                'forum over on Google Groups: ',
                                h('a', {
                                        href: 'https://groups.google.com/g/metaphor-language',
                                        target: '_blank',
                                        title: 'metaphor-language discussion group'
                                    },
                                    'groups.google.com/g/metaphor-language'
                                )
                            )
                        )
                    )
                )
            ),
            h('footer', {className: 'trailer'})
        ),
        pageFooter()
    );
}
