import {h, VNode} from '../lib/dvdi';

/**
 * Creates a reusable component for Humbug and Metaphor download information.
 *
 * @param showTitle - Whether to display the section title (default: true)
 * @param showMetaphor - Whether to display Metaphor-specific content (default: true)
 * @returns A VNode representing the download section
 */
export function downloadComponent(showTitle: boolean = true, showMetaphor: boolean = true): VNode {
    const children: VNode[] = [];

    if (showTitle) {
        children.push(h('h2', {}, 'Try Humbug and Metaphor now'));
    }

    children.push(h('p', {}, 'Download the latest version of Humbug', showMetaphor ? ', which includes Metaphor,' : '', ' for free:'));

    children.push(
        h('ul', {},
            h('li', {},
                h('a', {
                    href: 'https://github.com/m6r-ai/humbug/releases/download/v0.9/Humbug-v0.9-apple.dmg',
                    target: '_blank',
                    title: 'MacOS Apple silicon (M series systems) installer'
                },
                'MacOS - Apple silicon Macs (M series)'
                ),
            ),
            h('li', {},
                h('a', {
                    href: 'https://github.com/m6r-ai/humbug/releases/download/v0.9/Humbug-v0.9-intel.dmg',
                    target: '_blank',
                    title: 'MacOS Intel installer'
                },
                'MacOS - Intel Macs'
                ),
            ),
            h('li', {}, 'We will be adding more packaged versions soon.')
        )
    );

    children.push(
        h('p', {},
            'For all other platforms, and for source code, head to: ',
            h('a', {
                href: 'https://github.com/m6r-ai/humbug',
                target: '_blank',
                title: 'Humbug git repository on GitHub'
            },
            'github.com/m6r-ai/humbug'
            )
        )
    );

    if (showMetaphor) {
        children.push(
            h('p', {},
                'You can also find the standalone Metaphor tools on GitHub: ',
                h('a', {
                    href: 'https://github.com/m6r-ai/m6rc',
                    target: '_blank',
                    title: 'm6rc git repository on GitHub'
                },
                'github.com/m6r-ai/m6rc'
                ),
                ' and ',
                h('a', {
                    href: 'https://github.com/m6r-ai/m6rclib',
                    target: '_blank',
                    title: 'm6rclib git repository on GitHub'
                },
                'github.com/m6r-ai/m6rclib'
                )
            )
        );
    }

    return h('section', {}, ...children);
}
