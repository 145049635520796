import {svg, VNode} from '../lib/dvdi';

export function hamburgerIcon(): VNode {
    return svg('svg', {
            xmlns: 'http://www.w3.org/2000/svg',
            width: 24,
            height: 24,
            viewBox: '0 0 24 24',
            fill: 'none',
            stroke: 'currentColor',
            'stroke-width': 2,
            'stroke-linecap': 'round',
            'stroke-linejoin': 'round'
        },
        svg('line', {x1: 3, y1: 12, x2: 21, y2: 12}),
        svg('line', {x1: 3, y1: 6, x2: 21, y2: 6}),
        svg('line', {x1: 3, y1: 18, x2: 21, y2: 18})
    );
}
