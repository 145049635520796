import {h, VElement, VNode} from '../lib/dvdi';
import {hero} from '../components/hero';
import {pageFooter} from "../components/pageFooter";
import {pageHeader} from "../components/pageHeader";
import {navigateEvent} from '../app';
import {imageCarousel} from '../components/carousel';
import {downloadComponent} from '../components/download';

function homePageComponent(): VElement {
    // Define the carousel images
    const carouselImages = [
        {
            src: '/home/humbug-v0.9-1.webp',
            alt: 'Humbug in action',
            width: '1280',
            height: 'auto'
        },
        {
            src: '/home/humbug-v0.9-2.webp',
            alt: 'Humbug in action',
            width: '1280',
            height: 'auto'
        },
        {
            src: '/home/humbug-v0.9-3.webp',
            alt: 'Humbug in action',
            width: '1280',
            height: 'auto'
        },
        {
            src: '/home/humbug-v0.9-4.webp',
            alt: 'Humbug in action',
            width: '1280',
            height: 'auto'
        },
        {
            src: '/home/humbug-v0.9-5.webp',
            alt: 'Humbug in action',
            width: '1280',
            height: 'auto'
        }
    ];

    let contents: VElement =
        h('main', {className: 'main'},
            hero({
                title: 'AI-first development',
                subtitle: 'Reimagine your approach to designing, building, and maintaining software'
            }),
            h('main', {className: 'content'},
                h('div', {className: 'container'},
                    h('section', {},
                        h('h2', {}, 'Software done differently'),
                        h('p', {},
                            'AI can help you with you entire product and engineering journey, but it can\'t do that ' +
                            'if it\'s only being used to guess what you want to do inside your editor or IDE.'
                        ),
                        h('p', {},
                            'Our AI-first tools let you put AI at the core of every part of your software development ' +
                            'journey.  They let you focus on the results you want, helping you refine your vision, ' +
                            'build and review your code, work on your tests, and write your docs.'
                        ),
                        h('figure', {},
                            imageCarousel(carouselImages, 6000, 'humbug-carousel'),
                        ),
                        h('h2', {}, 'Humbug'),
                        h('p', {},
                            'An AI-first approach to software needs tools designed with AI at their core.  ',
                            h('a', {
                                    href: '/humbug',
                                    onclick: (e: MouseEvent) => navigateEvent(e, '/humbug')
                                },
                                'Humbug'
                            ),
                            ' is an extensible, open-source, front-end tool built for this.  It goes beyond simple app building ' +
                            'and prompt-based editing of files, giving you AI assistance over the whole lifecycle of ' +
                            'your software.  Whether you are building something new, or supporting something designed years ' +
                            'ago, Humbug can assist.'
                        ),
                        h('p', {},
                            'To let you use the right AI for any task, Humbug supports AIs from Anthropic, DeepSeek, Google, ' +
                            'Mistral, Ollama, and OpenAI.  You can even start things with one AI and continue them with a ' +
                            'different one.'
                        ),
                        h('h2', {}, 'Metaphor'),
                        h('p', {},
                            'Just like people, AIs need to understand the details of what you want your software ' +
                            'to do.  ',
                            h('a', {
                                    href: '/metaphor',
                                    onclick: (e: MouseEvent) => navigateEvent(e, '/metaphor')
                                },
                                'Metaphor'
                            ),
                            ' is a simple but powerful language lets you combine all the expert knowledge of your whole ' +
                            'team so your AIs can put it to use.'
                        ),
                        h('p', {},
                            'Humbug integrates Metaphor to give AIs what they need to help you throughout your software\'s ' +
                            'lifecycle.  It can help you add features, remove things you no longer want, upgrade package ' +
                            'dependencies, or even help you build an entirely new implementation!'
                        ),
                        h('p', {},
                            'Metaphor has been designed to work with different AI models so you can choose the model that ' +
                            'offers the right capabilities and costs for your needs, and is integrated into Humbug to make it ' +
                            'easy to use.'
                        ),
                        downloadComponent(),
                        h('section', {},
                            h('h2', {}, 'Frequently asked questions'),
                            h('p', {},
                                'Have questions about our approach or tools? Check out our ',
                                h('a', {
                                        href: '/faq',
                                        onclick: (e: MouseEvent) => navigateEvent(e, '/faq')
                                    },
                                    'FAQ page'
                                ),
                                ' for answers to common questions about Humbug, Metaphor, and AI-first development.'
                            )
                        ),
                        h('section', {},
                            h('h2', {}, 'View our YouTube channel'),
                            h('p', {},
                                'You can find out more on our ',
                                h('a', {
                                        href: 'https://youtube.com/@m6rai',
                                        target: '_blank',
                                        title: 'm6rai YouTube channel'
                                    },
                                    'YouTube'
                                ),
                                ' channel.'
                            )
                        ),
                        h('section', {},
                            h('h2', {}, 'Connect with us'),
                            h('p', {},
                                'Join us on ',
                                h('a', {href: 'https://discord.gg/GZhJ7ZtgwN', target: '_blank', title: 'Discord'},
                                    'Discord'
                                ),
                                ', or follow us on ',
                                h('a', {href: 'https://linkedin.com/company/m6r/', target: '_blank', title: 'LinkedIn'},
                                    'LinkedIn'
                                ),
                                ', to find out more about how Humbug and Metaphor can help you.'
                            )
                        )
                    )
                )
            )
        );

    return contents;
}

export function homePage(): VNode {
    return h('div', {className: 'app'},
        pageHeader(),
        homePageComponent(),
        pageFooter()
    );
}
