import {h, VElement} from '../lib/dvdi';

/**
 * Interface representing image data for the carousel.
 */
interface CarouselImage {
    src: string;
    alt: string;
    width?: string | number;
    height?: string | number;
}

/**
 * Creates an image carousel that automatically rotates between images.
 *
 * @param images - Array of image data to display in the carousel
 * @param interval - Time in milliseconds between automatic transitions (default: 5000ms)
 * @param className - Optional CSS class name for the carousel container
 * @returns A virtual DOM element representing the carousel
 */
export function imageCarousel(images: CarouselImage[], interval: number = 5000, className?: string): VElement {
    // Ensure we have at least one image
    if (images.length === 0) {
        return h('div', {className: 'carousel-empty'}, 'No images to display');
    }

    // State for tracking the current image index
    let currentIndex = 0;
    let intervalId: number | null = null;
    let isPaused = false;

    // Function to advance to the next image
    const nextImage = (e?: MouseEvent) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        // Update displayed images
        const carouselTrack = carouselElement.domElement?.querySelector('.carousel-track') as HTMLElement;
        if (!carouselTrack) return;

        currentIndex = (currentIndex + 1) % images.length;

        // Update transform to show the current image
        carouselTrack.style.transform = `translateX(-${currentIndex * 100}%)`;

        // Update active indicator
        updateActiveIndicator();

        // Reset the interval timer when manually navigating
        resetInterval();
    };

    // Function to go to the previous image
    const prevImage = (e?: MouseEvent) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        // Update displayed images
        const carouselTrack = carouselElement.domElement?.querySelector('.carousel-track') as HTMLElement;
        if (!carouselTrack) return;

        currentIndex = (currentIndex - 1 + images.length) % images.length;

        // Update transform to show the current image
        carouselTrack.style.transform = `translateX(-${currentIndex * 100}%)`;

        // Update active indicator
        updateActiveIndicator();

        // Reset the interval timer when manually navigating
        resetInterval();
    };

    // Function to update the active indicator
    const updateActiveIndicator = () => {
        const indicators = carouselElement.domElement?.querySelectorAll('.carousel-indicator');
        if (!indicators) return;

        indicators.forEach((indicator, index) => {
            if (index === currentIndex) {
                indicator.classList.add('active');
            } else {
                indicator.classList.remove('active');
            }
        });
    };

    // Function to go to a specific image index
    const goToIndex = (index: number, e?: MouseEvent) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (index === currentIndex) return;

        const carouselTrack = carouselElement.domElement?.querySelector('.carousel-track') as HTMLElement;
        if (!carouselTrack) return;

        currentIndex = index;
        carouselTrack.style.transform = `translateX(-${currentIndex * 100}%)`;

        // Update active indicator
        updateActiveIndicator();

        // Reset the interval timer
        resetInterval();
    };

    // Function to start the interval
    const startInterval = () => {
        if (intervalId !== null) clearInterval(intervalId);
        intervalId = window.setInterval(nextImage, interval);
    };

    // Function to reset the interval
    const resetInterval = () => {
        if (intervalId !== null) clearInterval(intervalId);
        if (!isPaused) {
            intervalId = window.setInterval(nextImage, interval);
        }
    };

    // Function to handle pause/resume on hover
    const handleMouseEnter = () => {
        isPaused = true;
        if (intervalId !== null) {
            clearInterval(intervalId);
            intervalId = null;
        }
    };

    const handleMouseLeave = () => {
        isPaused = false;
        startInterval();
    };

    // Create image elements
    const imageElements = images.map((image, index) => {
        return h('div', {className: 'carousel-item'},
            h('img', {
                src: image.src,
                alt: image.alt,
                width: image.width || 'auto',
                height: image.height || 'auto'
            })
        );
    });

    // Create indicator dots
    const indicatorElements = images.map((_, index) => {
        return h('button', {
            className: `carousel-indicator ${index === 0 ? 'active' : ''}`,
            onclick: (e: MouseEvent) => goToIndex(index, e),
            'aria-label': `Go to slide ${index + 1}`
        });
    });

    // Create the carousel element
    const carouselElement = h('div', {
        className: `carousel ${className || ''}`,
        onmouseenter: handleMouseEnter,
        onmouseleave: handleMouseLeave
    },
        h('div', {className: 'carousel-container'},
            h('div', {className: 'carousel-track'}, ...imageElements)
        ),
        h('button', {
            className: 'carousel-control carousel-control-prev',
            onclick: (e: MouseEvent) => prevImage(e),
            'aria-label': 'Previous slide'
        },
            h('span', {className: 'carousel-control-icon'}, '‹')
        ),
        h('button', {
            className: 'carousel-control carousel-control-next',
            onclick: (e: MouseEvent) => nextImage(e),
            'aria-label': 'Next slide'
        },
            h('span', {className: 'carousel-control-icon'}, '›')
        ),
        h('div', {className: 'carousel-indicators'}, ...indicatorElements)
    );

    // Set up the interval when the component is mounted
    carouselElement.mountCallback = () => {
        startInterval();

        // Initialize the track element's transform
        const carouselTrack = carouselElement.domElement?.querySelector('.carousel-track') as HTMLElement;
        if (carouselTrack) {
            carouselTrack.style.transform = 'translateX(0%)';
        }
    };

    // Clean up the interval when the component is unmounted
    carouselElement.unmountCallback = () => {
        if (intervalId !== null) {
            clearInterval(intervalId);
            intervalId = null;
        }
    };

    return carouselElement;
}
