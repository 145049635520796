import {VNode} from '../lib/dvdi'

export class BlogPost {
    title: string;
    author: string;
    dateTime: string;
    hRef: string;
    description: string;
    imageURL: string | null;
    articleFunction: () => VNode[];

    constructor(
        title: string,
        author: string,
        dateTime: string,
        hRef: string,
        description: string,
        imageURL: string | null,
        articleFunction: () => VNode[]
    ) {
        this.title = title;
        this.author = author;
        this.dateTime = dateTime;
        this.hRef = hRef;
        this.description = description;
        this.imageURL = imageURL;
        this.articleFunction = articleFunction;
    }
}
