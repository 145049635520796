import {svg, VNode} from '../lib/dvdi';

export function chevronLeftIcon(): VNode {
    return svg('svg', {
            xmlns: 'http://www.w3.org/2000/svg',
            width: 24,
            height: 24,
            viewBox: '0 0 24 24',
            fill: 'none',
            stroke: 'currentColor',
            'stroke-width': 2,
            'stroke-linecap': 'round',
            'stroke-linejoin': 'round'
        },
        svg('polyline', {points: '15 18 9 12 15 6'})
    );
}
