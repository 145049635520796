import {svg, VNode} from '../lib/dvdi';

export function m6rLogo(): VNode {
    return svg('svg', {
            xmlns: 'http://www.w3.org/2000/svg',
            'xmlns:xlink': 'http://www.w3.org/1999/xlink',
            width: 512,
            height: 512,
            zoomAndPan: 'magnify',
            viewBox: '0 0 384 384',
            preserveAspectRatio: 'xMidYMid meet',
            version: '1.0'
        },
        svg('rect', {
            x: -38.4,
            y: -38.4,
            width: 460.8,
            height: 460.8,
            fill: '#4040c0',
            'fill-opacity': 1
        }),
        svg('g', {
                fill: '#ffffff',
                'fill-opacity': 1
            },
            svg('g', {
                    transform: 'translate(38.4, 250.96)'
                },
                svg('g', {},
                    svg('path', {
                        d: 'M 97.234375 0 L 92.125 -64.078125 L 91.46875 -64.078125 L 69.328125 -0.65625 L 54.640625 -0.65625 L 32.5 -64.078125 L 31.84375 -64.078125 L 26.734375 0 L 2.09375 0 L 11.796875 -108.109375 L 38 -108.109375 L 61.984375 -45.734375 L 85.96875 -108.109375 L 112.171875 -108.109375 L 121.875 0 Z M 97.234375 0'
                    })
                )
            )
        ),
        svg('g', {
                fill: '#ffffff',
                'fill-opacity': 1
            },
            svg('g', {
                    transform: 'translate(162.225, 250.96)'
                },
                svg('g', {},
                    svg('path', {
                        d: 'M 76 -108.109375 L 44.15625 -71.03125 C 46 -72.070312 48.578125 -72.59375 51.890625 -72.59375 C 55.734375 -72.59375 59.617188 -71.785156 63.546875 -70.171875 C 67.484375 -68.554688 71.175781 -66.238281 74.625 -63.21875 C 78.082031 -60.207031 80.898438 -56.210938 83.078125 -51.234375 C 85.265625 -46.253906 86.359375 -40.753906 86.359375 -34.734375 C 86.359375 -28.609375 85.203125 -23.125 82.890625 -18.28125 C 80.578125 -13.4375 77.472656 -9.59375 73.578125 -6.75 C 69.691406 -3.90625 65.429688 -1.738281 60.796875 -0.25 C 56.171875 1.226562 51.328125 1.96875 46.265625 1.96875 C 34.117188 1.96875 23.894531 -1.566406 15.59375 -8.640625 C 7.289062 -15.722656 3.140625 -24.421875 3.140625 -34.734375 C 3.140625 -43.460938 4.363281 -50.644531 6.8125 -56.28125 C 9.257812 -61.914062 13.71875 -69.101562 20.1875 -77.84375 L 45.609375 -108.109375 Z M 28.703125 -34.734375 C 28.703125 -30.015625 30.226562 -26.101562 33.28125 -23 C 36.34375 -19.894531 40.273438 -18.34375 45.078125 -18.34375 C 49.878906 -18.34375 53.851562 -19.894531 57 -23 C 60.144531 -26.101562 61.71875 -30.015625 61.71875 -34.734375 C 61.71875 -39.535156 60.125 -43.53125 56.9375 -46.71875 C 53.75 -49.90625 49.796875 -51.5 45.078125 -51.5 C 40.535156 -51.5 36.671875 -49.90625 33.484375 -46.71875 C 30.296875 -43.53125 28.703125 -39.535156 28.703125 -34.734375 Z M 28.703125 -34.734375'
                    })
                )
            )
        ),
        svg('g', {
                fill: '#ffffff',
                'fill-opacity': 1
            },
            svg('g', {
                    transform: 'translate(249.067, 250.96)'
                },
                svg('g', {},
                    svg('path', {
                        d: 'M 7.859375 -108.109375 L 46.515625 -108.109375 C 56.910156 -108.109375 65.382812 -105.332031 71.9375 -99.78125 C 78.488281 -94.238281 81.765625 -86.3125 81.765625 -76 C 81.765625 -68.050781 79.773438 -61.390625 75.796875 -56.015625 C 71.828125 -50.648438 66.4375 -46.875 59.625 -44.6875 L 96.84375 0 L 65.515625 0 L 32.5 -42.71875 L 32.5 0 L 7.859375 0 Z M 32.5 -60.15625 L 35.375 -60.15625 C 37.65625 -60.15625 39.578125 -60.195312 41.140625 -60.28125 C 42.710938 -60.363281 44.503906 -60.664062 46.515625 -61.1875 C 48.523438 -61.71875 50.140625 -62.460938 51.359375 -63.421875 C 52.585938 -64.390625 53.640625 -65.789062 54.515625 -67.625 C 55.390625 -69.457031 55.828125 -71.679688 55.828125 -74.296875 C 55.828125 -76.921875 55.390625 -79.148438 54.515625 -80.984375 C 53.640625 -82.816406 52.585938 -84.210938 51.359375 -85.171875 C 50.140625 -86.140625 48.523438 -86.882812 46.515625 -87.40625 C 44.503906 -87.925781 42.710938 -88.226562 41.140625 -88.3125 C 39.578125 -88.40625 37.65625 -88.453125 35.375 -88.453125 L 32.5 -88.453125 Z M 32.5 -60.15625'
                    })
                )
            )
        )
    );
}
