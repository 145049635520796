import {h, VNode} from './dvdi';
import {navigateEvent} from '../app';
import {mailIcon, gitHubIcon, linkedInIcon, xIcon, m6rLogo} from './icons';

export function pageHeader(): VNode {
    const component = () => h('header', {className: 'header'},
        h('div', {className: 'container'},
            h('nav', {className: 'logo'},
                h('a', {href: '/', onclick: (e: MouseEvent) => navigateEvent(e, '/')},
                    m6rLogo()
                ),
            ),
            h('nav', {className: 'menu'},
                h('a', {href: '/metaphor', onclick: (e: MouseEvent) => navigateEvent(e, '/metaphor')}, 'Metaphor'),
                h('a', {href: '/blog', onclick: (e: MouseEvent) => navigateEvent(e, '/blog')}, 'Blog'),
                h('a', {href: '/company', onclick: (e: MouseEvent) => navigateEvent(e, '/company')}, 'Company')
            )
        )
    );

    let vNode = component();
    return vNode;
}

export function pageFooter(): VNode {
    return h('footer', {className: 'footer'},
        h('div', {className: 'container'},
            h('div', {className: 'copyright'},
                '© 2024 M6R Ltd.'
            ),
            h('nav', {className: 'social'},
                h('a', {className: 'icon', href: 'https://github.com/m6r-ai', target: '_blank', title: 'GitHub'},
                    gitHubIcon()
                ),
                h('a', {className: 'icon', href: 'https://x.com/m6rai', target: '_blank', title: 'X'},
                    xIcon()
                ),
                h('a', {className: 'icon', href: 'https://linkedin.com/company/m6r', target: '_blank', title: 'LinkedIn'},
                    linkedInIcon()
                ),
                h('a', {
                        className: 'icon',
                        href: 'mailto:hello@m6r.ai?subject=Email\ about\ m6r.ai',
                        title: 'Email'
                    },
                    mailIcon(),
                )
            )
        )
    );
}
