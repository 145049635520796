import {h, VNode} from '../lib/dvdi';
import {hero} from '../components/hero';
import {pageFooter} from "../components/pageFooter";
import {pageHeader} from "../components/pageHeader";

/**
 * Creates a FAQ item with collapsible answer
 *
 * @param question - The FAQ question text
 * @param answerContent - Array of VNodes representing the answer content
 * @returns A VElement representing the FAQ item
 */
function faqItem(question: string, answerContent: VNode[]): VNode {
    return h('div', {className: 'faq-item'},
        h('p', {
            className: 'faq-question',
            onclick: (e: MouseEvent) => {
                const target = e.currentTarget as HTMLElement;
                const answer = target.nextElementSibling as HTMLElement;
                const expanded = answer.classList.contains('expanded');

                // Toggle the expanded class
                if (expanded) {
                    answer.classList.remove('expanded');
                    target.setAttribute('aria-expanded', 'false');
                    answer.setAttribute('aria-hidden', 'true');
                } else {
                    answer.classList.add('expanded');
                    target.setAttribute('aria-expanded', 'true');
                    answer.setAttribute('aria-hidden', 'false');
                }
            },
            'aria-expanded': 'false',
            role: 'button',
            tabIndex: 0,
            onkeydown: (e: KeyboardEvent) => {
                if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    (e.currentTarget as HTMLElement).click();
                }
            }
        }, question),
        h('div', {
            className: 'faq-answer',
            'aria-hidden': 'true'
        }, ...answerContent)
    );
}

export function faqPage(): VNode {
    return h('div', {className: 'app'},
        pageHeader(),
        h('main', {className: 'main'},
            hero({
                title: 'Frequently asked questions',
                subtitle: 'Find answers to common questions about Humbug and Metaphor'
            }),
            h('main', {className: 'content'},
                h('div', {className: 'container'},
                    h('article', {},
                        h('section', {},
                            h('p', {},
                                'We\'ve collected answers to the most common questions about our products and ' +
                                'approaches. If you don\'t find what you\'re looking for, please reach out to us on ',
                                h('a', {href: 'https://discord.gg/GZhJ7ZtgwN', target: '_blank'}, 'Discord'),
                                ' or via ',
                                h('a', {href: 'mailto:hello@m6r.ai?subject=FAQ%20Question'}, 'email'),
                                '.'
                            ),
                            h('div', {className: 'faq-controls'},
                                h('button', {
                                    className: 'expand-all',
                                    onclick: () => {
                                        const faqAnswers = document.querySelectorAll('.faq-answer');
                                        const faqQuestions = document.querySelectorAll('.faq-question');

                                        faqAnswers.forEach(answer => {
                                            answer.classList.add('expanded');
                                            answer.setAttribute('aria-hidden', 'false');
                                        });

                                        faqQuestions.forEach(question => {
                                            question.setAttribute('aria-expanded', 'true');
                                        });
                                    }
                                }, 'Expand All'),
                                h('button', {
                                    className: 'collapse-all',
                                    onclick: () => {
                                        const faqAnswers = document.querySelectorAll('.faq-answer');
                                        const faqQuestions = document.querySelectorAll('.faq-question');

                                        faqAnswers.forEach(answer => {
                                            answer.classList.remove('expanded');
                                            answer.setAttribute('aria-hidden', 'true');
                                        });

                                        faqQuestions.forEach(question => {
                                            question.setAttribute('aria-expanded', 'false');
                                        });
                                    }
                                }, 'Collapse All')
                            )
                        ),
                        h('section', {id: 'humbug'},
                            h('h2', {}, 'Humbug'),
                            faqItem(
                                'What is Humbug?',
                                [
                                    h('p', {},
                                        'Humbug is an extensible, open-source tool designed for AI-first software development. ' +
                                        'It provides an integrated environment that helps you develop and support software throughout ' +
                                        'its lifecycle with AI assistance.'
                                    ),
                                    h('p', {},
                                        'Unlike AI code completion tools or AI app builders, Humbug is built to help you maintain and ' +
                                        'evolve your software over time, not just speed up editing or create the initial version.'
                                    )
                                ]
                            ),
                            faqItem(
                                'Which AI models does Humbug support?',
                                [
                                    h('p', {},
                                        'Humbug supports AI models from multiple providers including:'
                                    ),
                                    h('ul', {},
                                        h('li', {}, 'Anthropic (Claude models)'),
                                        h('li', {}, 'DeepSeek'),
                                        h('li', {}, 'Google (Gemini models)'),
                                        h('li', {}, 'Mistral'),
                                        h('li', {}, 'Ollama (for local models)'),
                                        h('li', {}, 'OpenAI (GPT models)')
                                    ),
                                    h('p', {},
                                        'This allows you to choose the best model for your specific needs, or use different models ' +
                                        'for different aspects of your projects.'
                                    )
                                ]
                            ),
                            faqItem(
                                'Is Humbug free to use?',
                                [
                                    h('p', {},
                                        'Yes, Humbug is open-source and free to download and use. The software itself doesn\'t ' +
                                        'have any usage fees. However, when using commercial AI models (like those from Anthropic, ' +
                                        'OpenAI, etc.), you will need to use your own API keys and pay for any usage according to ' +
                                        'those providers\' pricing models.'
                                    ),
                                    h('p', {},
                                        'Humbug also works with local models through Ollama, which can be used without API costs.'
                                    )
                                ]
                            ),
                            faqItem(
                                'What operating systems does Humbug support?',
                                [
                                    h('p', {},
                                        'Humbug is cross-platform and runs on:'
                                    ),
                                    h('ul', {},
                                        h('li', {}, 'MacOS'),
                                        h('li', {}, 'Windows'),
                                        h('li', {}, 'Linux')
                                    )
                                ]
                            ),
                            faqItem(
                                'How does Humbug compare to GitHub Copilot or other AI coding assistants?',
                                [
                                    h('p', {},
                                        'While tools like GitHub Copilot focus primarily on code completion and generation within ' +
                                        'existing editors, Humbug takes a more comprehensive approach:'
                                    ),
                                    h('ul', {},
                                        h('li', {}, 'Humbug integrates the Metaphor language to help structure complex tasks'),
                                        h('li', {}, 'It provides a multi-AI interaction environment where you can work with multiple models simultaneously'),
                                        h('li', {}, 'It\'s designed to capture and maintain context throughout the software lifecycle'),
                                        h('li', {}, 'It focuses on the full development process, not just code writing')
                                    ),
                                    h('p', {},
                                        'Humbug is designed as a complementary tool that can work alongside your existing development environment.'
                                    )
                                ]
                            ),
                            faqItem(
                                'Humbug seems an odd name - where does it come from?',
                                [
                                    h('p', {},
                                        'Humbug was the name of the v5.1 ROM monitor software on the first computer Dave used in 1979.'
                                    )
                                ]
                            ),
                            faqItem(
                                'How can I contribute to Humbug?',
                                [
                                    h('p', {},
                                        'As an open-source project, Humbug welcomes contributions! You can contribute by:'
                                    ),
                                    h('ul', {},
                                        h('li', {}, 'Submitting pull requests on GitHub'),
                                        h('li', {}, 'Reporting bugs or suggesting features through issues'),
                                        h('li', {}, 'Improving documentation'),
                                        h('li', {}, 'Sharing your experiences and use cases in the community')
                                    ),
                                    h('p', {},
                                        'Visit the ',
                                        h('a', {href: 'https://github.com/m6r-ai/humbug', target: '_blank'}, 'Humbug GitHub repository'),
                                        ' to get started.'
                                    )
                                ]
                            )
                        ),
                        h('section', {id: 'metaphor'},
                            h('h2', {}, 'Metaphor'),
                            faqItem(
                                'What is Metaphor?',
                                [
                                    h('p', {},
                                        'Metaphor is a simple prompt creation language designed to help capture software requirements, ' +
                                        'design decisions, and context in a structured way that AI can use to help build and maintain software.'
                                    ),
                                    h('p', {},
                                        'It allows you to describe what you want your software to do, rather than focusing on ' +
                                        'implementation details, and can incorporate knowledge from across your team to create comprehensive ' +
                                        'requirements that AI can work with.'
                                    )
                                ]
                            ),
                            faqItem(
                                'How is Metaphor different from just writing prompts?',
                                [
                                    h('p', {},
                                        'Unlike ad-hoc prompts, Metaphor provides a structured approach with several advantages:'
                                    ),
                                    h('ul', {},
                                        h('li', {}, 'A consistent format that can be shared, versioned, and reused'),
                                        h('li', {},
                                            'The ability to modularize and compose Metaphor files by using the ',
                                            h('code', {}, 'Include:'),
                                            ' keyword'
                                        ),
                                        h('li', {},
                                            'Providing files as context to a prompt by using the ',
                                            h('code', {}, 'Embed:'),
                                            ' keyword'
                                        ),
                                        h('li', {}, 'Integration with development workflows and version control'),
                                        h('li', {}, 'A focus on capturing the "what" and "why" of software, not just the "how"')
                                    ),
                                    h('p', {},
                                        'This structured approach helps reduce ambiguity and makes it easier to maintain and ' +
                                        'evolve software over time.'
                                    )
                                ]
                            ),
                            faqItem(
                                'Do I need to learn a new programming language to use Metaphor?',
                                [
                                    h('p', {},
                                        'No, Metaphor is intentionally simple and primarily uses natural language. It has only a few keywords ' +
                                        '(',
                                        h('code', {}, 'Role:'),
                                        ', ',
                                        h('code', {}, 'Context:'),
                                        ', ',
                                        h('code', {}, 'Action:'),
                                        ', ',
                                        h('code', {}, 'Include:'),
                                        ', and ',
                                        h('code', {}, 'Embed:'),
                                        ') to structure your content, but the majority of ' +
                                        'what you write is in plain English (or your preferred language).'
                                    ),
                                    h('p', {},
                                        'Metaphor is designed to be accessible to everyone on a product team, not just developers. Product ' +
                                        'managers, designers, QA analysts, and other stakeholders can all contribute to Metaphor descriptions.'
                                    )
                                ]
                            ),
                            faqItem(
                                'Can I use Metaphor without Humbug?',
                                [
                                    h('p', {},
                                        'Yes, Metaphor is available as a standalone library (m6rclib) and command-line tool (m6rc) that ' +
                                        'you can use independently of Humbug. These tools allow you to compile Metaphor descriptions ' +
                                        'into prompts that can be used with any AI service.'
                                    ),
                                    h('p', {},
                                        'You can find these tools at ',
                                        h('a', {href: 'https://github.com/m6r-ai/m6rclib', target: '_blank'}, 'github.com/m6r-ai/m6rclib'),
                                        ' and ',
                                        h('a', {href: 'https://github.com/m6r-ai/m6rc', target: '_blank'}, 'github.com/m6r-ai/m6rc'),
                                        '.'
                                    )
                                ]
                            ),
                            faqItem(
                                'How does Metaphor handle version control and collaboration?',
                                [
                                    h('p', {},
                                        'Metaphor files (',
                                        h('code', {}, '.m6r'),
                                        ') are plain text files that can be stored in your existing version control ' +
                                        'system (like Git). This allows you to:'
                                    ),
                                    h('ul', {},
                                        h('li', {}, 'Track changes to requirements over time'),
                                        h('li', {}, 'Use branch-based workflows for new features'),
                                        h('li', {}, 'Perform code reviews on requirement changes'),
                                        h('li', {}, 'Collaborate across teams using familiar tools')
                                    ),
                                    h('p', {},
                                        'The modular nature of Metaphor (with the Include: keyword) also supports breaking down large ' +
                                        'projects into manageable components that different teams can work on.'
                                    )
                                ]
                            ),
                            faqItem(
                                'Where can I learn more about using Metaphor?',
                                [
                                    h('p', {},
                                        'You can find tutorials and examples at:'
                                    ),
                                    h('ul', {},
                                        h('li', {},
                                            h('a', {href: 'https://github.com/m6r-ai/hello-metaphor', target: '_blank'}, 'Hello Metaphor tutorial')
                                        ),
                                        h('li', {},
                                            h('a', {href: 'https://github.com/m6r-ai/commit-critic', target: '_blank'}, 'commit-critic example')
                                        ),
                                        h('li', {},
                                            h('a', {href: 'https://github.com/m6r-ai/demo-blog-editor', target: '_blank'}, 'Blog editor example')
                                        ),
                                        h('li', {},
                                            h('a', {href: 'https://github.com/m6r-ai/demo-code-review', target: '_blank'}, 'Code review example')
                                        )
                                    ),
                                    h('p', {},
                                        'You can also check our ',
                                        h('a', {href: 'https://youtube.com/@m6rai', target: '_blank'}, 'YouTube channel'),
                                        ' for video tutorials and demonstrations.'
                                    )
                                ]
                            )
                        ),
                        h('section', {},
                            h('h2', {}, 'Need more help?'),
                            h('p', {},
                                'If you couldn\'t find the answer to your question, please reach out to us through one of these channels:'
                            ),
                            h('ul', {},
                                h('li', {},
                                    'Join our ',
                                    h('a', {href: 'https://discord.gg/GZhJ7ZtgwN', target: '_blank'}, 'Discord community'),
                                    ' for direct support and discussion'
                                ),
                                h('li', {},
                                    'Contact us via email at ',
                                    h('a', {href: 'mailto:hello@m6r.ai?subject=Support%20Question'}, 'hello@m6r.ai')
                                ),
                                h('li', {},
                                    'Follow us on ',
                                    h('a', {href: 'https://linkedin.com/company/m6r/', target: '_blank'}, 'LinkedIn'),
                                    ' for updates and announcements'
                                )
                            )
                        )
                    )
                )
            )
        ),
        pageFooter(),
    );
}
