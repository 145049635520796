import {svg, VNode} from '../lib/dvdi';

export function xIcon(): VNode {
    return svg('svg', {
            xmlns: 'http://www.w3.org/2000/svg',
            width: 24,
            height: 24,
            viewBox: '0 0 24 24',
            fill: 'none',
            stroke: 'currentColor',
            'stroke-width': 2,
            'stroke-linecap': 'round',
            'stroke-linejoin': 'round'
        },
        svg('polygon', {points: '21.3,21.1 9.9,2.9 2.7,2.9 14.1,21.1'}),
        svg('line', {x1: 2.7, y1: 21.1, x2: 9.9, y2: 14.5}),
        svg('line', {x1: 14.1, y1: 9.5, x2: 21.3, y2: 2.9})
    );
}
