import {h, VNode} from '../lib/dvdi';
import {navigateEvent} from '../app';
import {closeIcon} from './closeIcon';
import {hamburgerIcon} from './hamburgerIcon';
import {m6rLogo} from './m6rLogo';

export function pageHeader(): VNode {
    // Create header element
    const headerElement = h('header', {className: 'header'},
        h('div', {className: 'container'},
            h('nav', {className: 'logo'},
                h('a', {href: '/', onclick: (e: MouseEvent) => navigateEvent(e, '/')},
                    m6rLogo()
                ),
            ),
            h('nav', {className: 'menu'},
                h('a', {href: '/humbug', onclick: (e: MouseEvent) => navigateEvent(e, '/humbug')}, 'Humbug'),
                h('a', {href: '/metaphor', onclick: (e: MouseEvent) => navigateEvent(e, '/metaphor')}, 'Metaphor'),
                h('a', {href: '/blog', onclick: (e: MouseEvent) => navigateEvent(e, '/blog')}, 'Blog'),
                h('a', {href: '/company', onclick: (e: MouseEvent) => navigateEvent(e, '/company')}, 'Company')
            ),
            h('div', {className: 'mobile-menu'},
                h('button', {
                    className: 'hamburger-button',
                    'aria-label': 'Toggle menu',
                    onclick: (e: MouseEvent) => {
                        e.stopPropagation();
                        const mobileMenuElement = document.querySelector('.mobile-menu-dropdown') as HTMLElement;
                        if (mobileMenuElement) {
                            const wasOpen = mobileMenuElement.classList.contains('open');
                            mobileMenuElement.classList.toggle('open');
                            // Add/remove class for showing the appropriate icon
                            const buttonElement = e.currentTarget as HTMLElement;
                            if (wasOpen) {
                                buttonElement.classList.remove('menu-open');
                            } else {
                                buttonElement.classList.add('menu-open');
                            }
                        }
                    }
                },
                h('span', {className: 'hamburger-icon'}, hamburgerIcon()),
                h('span', {className: 'close-icon'}, closeIcon())
                ),
                h('div', {className: 'mobile-menu-dropdown'},
                    h('nav', {className: 'mobile-menu-items'},
                        h('a', {
                            href: '/humbug',
                            onclick: (e: MouseEvent) => {
                                closeMenu();
                                navigateEvent(e, '/humbug');
                            }
                        }, 'Humbug'),
                        h('a', {
                            href: '/metaphor',
                            onclick: (e: MouseEvent) => {
                                closeMenu();
                                navigateEvent(e, '/metaphor');
                            }
                        }, 'Metaphor'),
                        h('a', {
                            href: '/blog',
                            onclick: (e: MouseEvent) => {
                                closeMenu();
                                navigateEvent(e, '/blog');
                            }
                        }, 'Blog'),
                        h('a', {
                            href: '/company',
                            onclick: (e: MouseEvent) => {
                                closeMenu();
                                navigateEvent(e, '/company');
                            }
                        }, 'Company')
                    )
                )
            )
        )
    );

    const closeMenu = () => {
        const mobileMenuElement = document.querySelector('.mobile-menu-dropdown') as HTMLElement;
        const buttonElement = document.querySelector('.hamburger-button') as HTMLElement;
        if (mobileMenuElement) {
            mobileMenuElement.classList.remove('open');
        }
        if (buttonElement) {
            buttonElement.classList.remove('menu-open');
        }
    };

    // Handle document click to close mobile menu when clicking outside
    const documentClickHandler = (e: MouseEvent) => {
        const mobileMenuElement = document.querySelector('.mobile-menu-dropdown');
        const hamburgerButton = document.querySelector('.hamburger-button');

        if (mobileMenuElement && mobileMenuElement.classList.contains('open') &&
            hamburgerButton && !hamburgerButton.contains(e.target as Node) &&
            !mobileMenuElement.contains(e.target as Node)) {
            closeMenu();
        }
    };

    // Set up and clean up event listeners when the component mounts/unmounts
    headerElement.mountCallback = () => {
        document.addEventListener('click', documentClickHandler);
    };

    headerElement.unmountCallback = () => {
        document.removeEventListener('click', documentClickHandler);
    };

    return headerElement;
}
