import {h, VNode} from '../lib/dvdi';
import {hero} from '../components/hero';
import {pageFooter} from "../components/pageFooter";
import {pageHeader} from "../components/pageHeader";

export function companyPage(): VNode {
    return h('div', {className: 'app'},
        pageHeader(),
        h('main', {className: 'main'},
            hero({
                title: 'Company Information'
            }),
            h('main', {className: 'content'},
                h('div', {className: 'container'},
                    h('article', {},
                        h('section', {},
                            h('h2', {}, 'About us'),
                            h('p', {},
                                'We\'re obsessed with using AI to bring you an entirely new way to build software.  Our vision is to ' +
                                'bring you the tools you\'ll need to help you do that.'
                            )
                        ),
                        h('section', {},
                            h('h2', {}, 'Getting our help'),
                            h('p', {},
                                'We know that embracing a new way to do things can always be a little tricky at first.  If you\'re ' +
                                'looking to use Metaphor to help you with your development journey we can offer consulting services ' +
                                'to get you started, or we can put you in touch with some outstanding partners who will be able to ' +
                                'assist.  Please feel free to ',
                                h('a', {href: 'mailto:hello@m6r.ai?subject=Email\ about\ m6r.ai'}, 'email us (hello@m6r.ai)'),
                                ' for more information.'
                            )
                        ),
                        h('section', {},
                            h('h2', {}, 'Privacy'),
                            h('p', {},
                                'This site does not make use of cookies (which is why you don\'t get asked about them).  The back-end ' +
                                'server does collect logs for up to 28 days, to let us understand how the site is behaving.'
                            )
                        ),
                        h('section', {},
                            h('h2', {}, 'Connect with us'),
                            h('p', {},
                                'Connect with us via: ',
                                h('a', {href: 'https://linkedin.com/company/m6r/', target: '_blank', title: 'LinkedIn'},
                                    'LinkedIn'
                                ),
                                ', ',
                                h('a', {href: 'https://discord.gg/GZhJ7ZtgwN', target: '_blank', title: 'Discord'},
                                    'Discord'
                                ),
                                ', or ',
                                h('a', {href: 'http://x.com/m6rai', target: '_blank', title: 'X'}, 'X')
                            )
                        ),
                        h('section', {},
                            h('h2', {}, 'Company details'),
                            h('p', {},
                                'M6R Ltd'
                            ),
                            h('ul', {className: 'plain-list'},
                                h('li', {}, '128 City Road'),
                                h('li', {}, 'London'),
                                h('li', {}, 'EC1V 2NX'),
                                h('li', {}, 'United Kingdom')
                            ),
                            h('p', {},
                                'Email us: ',
                                h('a', {href: 'mailto:hello@m6r.ai?subject=Email\ about\ m6r.ai'}, 'hello@m6r.ai')
                            ),
                            h('p', {},
                                'Registered in England and Wales.  Company number: 15975430.'
                            ),
                            h('p', {},
                                'VAT registration number: 477039956.'
                            )
                        )
                    )
                )
            )
        ),
        pageFooter()
    );
}
