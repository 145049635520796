import {h, VElement, VNode} from '../lib/dvdi';
import {chevronLeftIcon} from '../components/chevronLeftIcon';
import {chevronRightIcon} from '../components/chevronRightIcon';
import {hero} from '../components/hero';
import {pageFooter} from "../components/pageFooter";
import {pageHeader} from "../components/pageHeader";
import {navigateEvent, routeDetails} from '../app';
import {blogPost_000001} from './000001-Founding-M6R/post';
import {blogPost_000002} from './000002-Convincing-a-sceptic/post';
import {blogPost_000003} from './000003-Introducing-Metaphor/post';
import {blogPost_000004} from './000004-Code-reviews-using-Metaphor/post';
import {blogPost_000005} from './000005-commit-critic/post';
import {blogPost_000006} from './000006-AI-first-software-development/post';
import {blogPost_000007} from './000007-Humbug-v05/post';
import {blogPost_000008} from './000008-Vibe-coding/post';
import {blogPost_000009} from './000009-Initial-thoughts-on-Google-Gemini-25/post';
import {BlogPost} from './BlogPost';

// Enumerate all the blog content served up here.  Newest content goes at the end.
const blogContent: BlogPost[] = [
    blogPost_000001,
    blogPost_000002,
    blogPost_000003,
    blogPost_000004,
    blogPost_000005,
    blogPost_000006,
    blogPost_000007,
    blogPost_000008,
    blogPost_000009
]

function navPrevNext(prevStr: string | null, prevHRef: string | null, nextStr: string | null, nextHRef: string | null): VNode {
    if (!prevStr && !nextStr) {
        return h('div', {})
    }

    return h('nav', {className: 'prev-next'},
        h('h2', {}, 'More blog posts'),
        h('table', {className: 'meta-nav'},
            h('tr', {},
                h('td', {className: 'prev'},
                    !prevStr ? '' : h('a', {
                            className: 'icon',
                            href: (prevHRef as string),
                            'aria-label': prevStr,
                            onclick: (e: MouseEvent) => navigateEvent(e, (prevHRef as string))
                        },
                        chevronLeftIcon()
                    )
                ),
                h('td', {className: 'prev-text'},
                    !prevStr ? '' : h('a', {
                            href: (prevHRef as string),
                            onclick: (e: MouseEvent) => navigateEvent(e, (prevHRef as string))
                        },
                        prevStr
                    )
                ),
                h('td', {className: 'next-text'},
                    !nextStr ? '' : h('a', {
                            href: (nextHRef as string),
                            onclick: (e: MouseEvent) => navigateEvent(e, (nextHRef as string))
                        },
                        nextStr
                    )
                ),
                h('td', {className: 'next'},
                    !nextStr ? '' : h('a', {
                            className: 'icon',
                            href: (nextHRef as string),
                            'aria-label': nextStr,
                            onclick: (e: MouseEvent) => navigateEvent(e, (nextHRef as string))
                        },
                        chevronRightIcon()
                    )
                )
            )
        )
    );
}

/**
 * Format a date string in a consistent format
 */
function formatDate(dateTime: string): string {
    // Parse the ISO 8601 date string into a Date object
    const date = new Date(dateTime);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    let formattedDate = `${year}-${month}-${day}`;

    // Include time if present in the original string
    if (dateTime.length > 10) {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        formattedDate += ` ${hours}:${minutes}`;
    }

    return formattedDate;
}

function blogArticlePage(index: number): VNode {
    let prevArticle = (index > 0) ? blogContent[index - 1] : null;
    let thisArticle = blogContent[index];
    let nextArticle = (index < (blogContent.length - 1)) ? blogContent[index + 1] : null;
    let prevTitle = prevArticle ? prevArticle.title : null;
    let prevHRef = prevArticle ? prevArticle.hRef : null;
    let nextTitle = nextArticle ? nextArticle.title : null;
    let nextHRef = nextArticle ? nextArticle.hRef : null;

    return h('div', {className: 'app'},
        pageHeader(),
        h('main', {className: 'main'},
            hero({
                title: thisArticle.title,
                subtitle: thisArticle.author + ' - ' + formatDate(thisArticle.dateTime)
            }),
            h('main', {className: 'content'},
                h('div', {className: 'container'},
                    h('article', {},
                        ...thisArticle.articleFunction()
                    ),
                    navPrevNext(prevTitle, prevHRef, nextTitle, nextHRef)
                )
            )
        ),
        pageFooter()
    );
}

function blogLink(href: string, title: string, meta: string) {
    return h('div', {className: 'blog-post'},
        h('span', {},
            h('a', {href: href, onclick: (e: MouseEvent) => navigateEvent(e, href)}, title)
        ),
        h('span', {className: 'meta'}, meta)
    )
}

// Handle generating the '/blog' page
export function blogPage() {
    let pageView: VNode[] = [];
    let yearSection: (VNode | null) = null;
    let headlineYear: number = 0;

    // Iterate all the blog content and create year and item enties.
    for (let i = blogContent.length - 1; i >= 0; i--) {
        const {hRef, title, dateTime} = blogContent[i];

        // Parse the ISO 8601 date string into a Date object
        const date = new Date(dateTime);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`

        if (headlineYear !== year) {
            if (yearSection !== null) {
                pageView.push(yearSection)
            }

            headlineYear = year;
            yearSection = h('section', {},
                h('h2', {}, `${year}`)
            )
        }

        (yearSection as VElement).appendChild(blogLink(hRef, title, formattedDate));
    }

    const sections = [...pageView, (yearSection as VNode)];

    // Return the VDOM for the blog page.
    return h('div', {className: 'app'},
        pageHeader(),
        h('main', {className: 'main'},
            hero({
                title: 'Blog posts'
            }),
            h('main', {className: 'content'},
                h('div', {className: 'container'},
                    h('div', {className: 'blog-posts'}, ...sections)
                )
            )
        ),
        pageFooter()
    );
}

// Collect all the routes to be used with the blog pages.
export function getBlogRoutes() {
    let blogRoutes: Map<string, routeDetails> = new Map();

    for (let i = 0; i < blogContent.length; i++) {
        let img = blogContent[i].imageURL;
        if (img === null) {
            img = '/icons/android-chrome-512x512.png';
        }

        const imgURL = 'https://m6r.ai' + img;

        blogRoutes.set(blogContent[i].hRef, {
            title: blogContent[i].title,
            render: () => blogArticlePage(i),
            description: blogContent[i].description,
            imageURL: imgURL,
            pageType: 'article'
        });
    }

    return blogRoutes;
}
