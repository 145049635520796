import {h, VNode} from '../lib/dvdi';
import {navigateEvent} from '../app';
import {downloadComponent} from '../components/download';
import {hero} from '../components/hero';
import {pageFooter} from "../components/pageFooter";
import {pageHeader} from "../components/pageHeader";

export function metaphorPage(): VNode {
    return h('div', {className: 'app'},
        pageHeader(),
        h('main', {className: 'main'},
            hero({
                title: 'Metaphor',
                subtitle: 'A language designed for building software with AI'
            }),
            h('main', {className: 'content'},
                h('div', {className: 'container'},
                    h('article', {},
                        h('section', {},
                            h('p', {},
                                'Conventional software development can create islands of disconnected information.  Product ' +
                                'requirements, engineering implementation details, testing, security, and deployment are often ' +
                                'captured in different ways.  Left unchecked, ambiguous or inconsistent elements can find their ' +
                                'way into implementations, confusing everyone and leading to high costs to correct them.'
                            ),
                            h('p', {},
                                'When we add AI into this, things can get much worse.  Our AIs only know about these things ' +
                                'if we tell them, so we risk asking AIs to do things with far less understanding than we\'d ' +
                                'ask people to do'
                            ),
                            h('p', {},
                                'Metaphor is a simple prompt creation language designed to solve this.  It provides a way to ' +
                                'capture all these details to allow AIs to have a clear idea what you want and how they can ' +
                                'help you.  Metaphor focuses on what you want your software to do and can capture knowledge and ' +
                                'expertise from across your team.'
                            )
                        ),
                        h('section', {},
                            h('h2', {}, 'What makes Metaphor different?'),
                            h('p', {},
                                'Metaphor encourages you to work at a higher level.  It\'s designed to let you focus ' +
                                'on what you want your software to do, not how it\'s built.  As new requirements and ' +
                                'capabilities are needed, they can be captured in the Metaphor description of your software and ' +
                                'checked to ensure they align with previous elements.  Once you have a finished description, it ' +
                                'can be passed to AI agents to help deliver the implementation, including any tests and ' +
                                'documentation you might need.'
                            ),
                            h('p', {},
                                'By focusing on what you want and why, your investment is also future-proofed.  It can help you ' +
                                'maintain and modify what you build.  And, with each new generation of AI agent, you can use ' +
                                'their enhanced capabilities to help you continually build better software.'
                            ),
                            h('figure', {},
                                h('img', {
                                    src: '/metaphor/humbug-v0.9-2.webp',
                                    alt: 'Metaphor in action',
                                    width: '1280',
                                    height: 'auto'
                                })
                            ),
                        ),
                        h('section', {},
                            h('h2', {}, 'Iterate quickly'),
                            h('p', {},
                                'As AI agents do implementation work rapidly, trying new ideas and discarding ones you don\'t ' +
                                'like is easy.  This means you can afford to try a few different approaches if you\'re unsure ' +
                                'which one might work best rather than settling for the first one you develop.'
                            ),
                            h('p', {},
                                'Like conventional software development, Metaphor encourages teams to use version control and ' +
                                'careful review before accepting changes.  This also helps keep everyone aligned and ' +
                                'dramatically improves time to value creation.'
                            )
                        ),
                        downloadComponent(),
                        h('section', {},
                            h('h2', {}, 'Frequently asked questions'),
                            h('p', {},
                                'Have questions about Metaphor? Visit our ',
                                h('a', {
                                        href: '/faq#metaphor',
                                        onclick: (e: MouseEvent) => navigateEvent(e, '/faq#metaphor')
                                    },
                                    'FAQ page'
                                ),
                                ' to find answers to common questions about the language, its features, and how to use it effectively.'
                            )
                        ),
                        h('section', {},
                            h('h2', {}, 'Examples'),
                            h('p', {},
                                'You can find examples powered by Metaphor on GitHub.  Some of these are demos we\'ve written, ' +
                                'and some are tools we use to help us build our software!',
                                h('ul', {},
                                    h('li', {},
                                        h('a', {
                                                href: 'https://github.com/m6r-ai/commit-critic',
                                                target: '_blank',
                                                title: 'code-review2 git repo on GitHub'
                                            },
                                            'github.com/m6r-ai/commit-critic'
                                        ),
                                        ' - a source code reviewing application written using m6rc and that also uses m6rclib.  ' +
                                        'Read more about this at: ',
                                        h('a', {
                                                href: '/blog/000005-commit-critic',
                                                onclick: (e: MouseEvent) => navigateEvent(
                                                    e, '/blog/000005-commit-critic'
                                                )
                                            },
                                            'commit-critic: An AI-powered, AI-built code review tool'
                                        ),
                                    ),
                                    h('li', {},
                                        h('a', {
                                                href: 'https://github.com/m6r-ai/demo-blog-editor',
                                                target: '_blank',
                                                title: 'demo-blog-editor git repo on GitHub'
                                            },
                                            'github.com/m6r-ai/demo-blog-editor'
                                        ),
                                        ' - an example of using m6rc to convert a markdown file (a blog post) into TypeScript ' +
                                        'code that uses a custom HTML format.'
                                    ),
                                    h('li', {},
                                        h('a', {
                                                href: 'https://github.com/m6r-ai/demo-code-review',
                                                target: '_blank',
                                                title: 'demo-code-review git repo on GitHub'
                                            },
                                            'github.com/m6r-ai/demo-code-review'
                                        ),
                                        ' - an example of using m6rc to set up a review of Python or TypeScript source code.  ' +
                                        'Read more about this at: ',
                                        h('a', {
                                                href: '/blog/000004-Code-reviews-using-Metaphor',
                                                onclick: (e: MouseEvent) => navigateEvent(
                                                    e, '/blog/000004-Code-reviews-using-Metaphor'
                                                )
                                            },
                                            'Code reviews using Metaphor'
                                        )
                                    ),
                                )
                            ),
                        ),
                        h('section', {},
                            h('h2', {}, 'View our YouTube channel'),
                            h('p', {},
                                'You can find out more on our ',
                                h('a', {
                                        href: 'https://youtube.com/@m6rai',
                                        target: '_blank',
                                        title: 'm6rai YouTube channel'
                                    },
                                    'YouTube'
                                ),
                                ' channel.'
                            )
                        ),
                        h('section', {},
                            h('h2', {}, 'Connect with us'),
                            h('p', {},
                                'Join us on ',
                                h('a', {href: 'https://discord.gg/GZhJ7ZtgwN', target: '_blank', title: 'Discord'},
                                    'Discord'
                                ),
                                ', or follow us on ',
                                h('a', {href: 'https://linkedin.com/company/m6r/', target: '_blank', title: 'LinkedIn'},
                                    'LinkedIn'
                                ),
                                ', to find out more about how Metaphor can help you.'
                            )
                        )
                    )
                )
            )
        ),
        pageFooter()
    );
}
