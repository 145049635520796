import {blogPage, getBlogRoutes} from './blog/blog';
import {companyPage} from './company/company';
import {h, updateElement, VNode} from './lib/dvdi';
import {pageHeader, pageFooter} from "./lib/page";
import {homePage} from './home/home';
import {metaphorPage} from './metaphor/metaphor';

function notFoundPage(path: string): VNode {
    return h('div', {className: 'app'},
        pageHeader(),
        h('main', {className: 'main' },
            h('header', {className: 'banner'},
                h('div', {className: 'container'},
                    h('h1', {}, `404: Page "${path}" not found`),
                    h('p', {}, 'This is unlikely to be the page you were looking for!')
                )
            ),
            h('footer', {className: 'trailer'})
        ),
        pageFooter()
    );
}

/**
 * Interface representing everything required to render a page.
 */
export interface routeDetails {
    title: string;
    render: () => VNode;
    description: string;
    imageURL: string;
    pageType: string;
}

let routes: Map<string, routeDetails> = new Map([
    ['', {
        title: 'M6R Ltd.',
        render: homePage,
        description: 'M6R Ltd.',
        imageURL: 'https://m6r.ai/icons/android-chrome-512x512.png',
        pageType: 'website'
    }],
    ['/blog', {
        title: 'Blog posts',
        render: blogPage,
        description: 'All M6R\'s blog posts, presented in date order with the most recent posts at the top ' +
            'of the page.',
        imageURL: 'https://m6r.ai/icons/android-chrome-512x512.png',
        pageType: 'website'
    }],
    ['/company', {
        title: 'Company Information',
        render: companyPage,
        description: 'An brief introduction to M6R, what we do, and how to contact us.',
        imageURL: 'https://m6r.ai/icons/android-chrome-512x512.png',
        pageType: 'profile'
    }],
    ['/metaphor', {
        title: 'Metaphor',
        render: metaphorPage,
        description: 'All about Metaphor, what it does, and how to use it.',
        imageURL: 'https://m6r.ai/icons/android-chrome-512x512.png',
        pageType: 'website'
    }]
]);

let rootVNode: VNode | null = null;

/**
 * Update page metadata.
 *
 * @param pageInfo - information about the page we're updating.
 */
function handleMetadata(pageInfo: routeDetails) {
    const metaDescription: HTMLMetaElement | null = document.querySelector('meta[name="description"]');
    if (metaDescription !== null) {
        metaDescription.content = pageInfo.description;
    }
}

/**
 * Update page open graph metadata.
 *
 * @param pageInfo - information about the page we're updating.
 */
function handleOGMetadata(pageInfo: routeDetails) {
    const metaOGType: HTMLMetaElement | null = document.querySelector('meta[property="og:type"]');
    if (metaOGType !== null) {
        metaOGType.content = pageInfo.pageType;
    }

    const metaOGTitle: HTMLMetaElement | null = document.querySelector('meta[property="og:title"]');
    if (metaOGTitle !== null) {
        metaOGTitle.content = pageInfo.title;
    }

    const metaOGDescription: HTMLMetaElement | null = document.querySelector('meta[property="og:description"]');
    if (metaOGDescription !== null) {
        metaOGDescription.content = pageInfo.description;
    }

    const metaOGURL: HTMLMetaElement | null = document.querySelector('meta[property="og:url"]');
    if (metaOGURL !== null) {
        metaOGURL.content = 'https://m6r.ai' + window.location.pathname;
    }

    const metaOGImage: HTMLMetaElement | null = document.querySelector('meta[property="og:image"]');
    if (metaOGImage !== null) {
        metaOGImage.content = pageInfo.imageURL;
    }
}

/**
 * Update page twitter metadata.
 *
 * @param pageInfo - information about the page we're updating.
 */
function handleTwitterMetadata(pageInfo: routeDetails) {
    const metaTwitterTitle: HTMLMetaElement | null = document.querySelector('meta[name="twitter:title"]');
    if (metaTwitterTitle !== null) {
        metaTwitterTitle.content = pageInfo.title;
    }

    const metaTwitterDescription: HTMLMetaElement | null = document.querySelector('meta[name="twitter:description"]');
    if (metaTwitterDescription !== null) {
        metaTwitterDescription.content = pageInfo.description;
    }

    const metaTwitterImage: HTMLMetaElement | null = document.querySelector('meta[name="twitter:image"]');
    if (metaTwitterImage !== null) {
        metaTwitterImage.content = pageInfo.imageURL;
    }
}

/**
 * Handle navigation to a new route.
 */
function handleLocation() {
    let path = window.location.pathname;

    console.log(`Navigating to ${path}`)

    // If there's a trailing slash on the path, remove it.
    if (path.endsWith('/')) {
        path = path.slice(0, -1);
    }

    let pageInfo = {
        title: '404 - Not found',
        render: () => notFoundPage(path),
        description: '',
        imageURL: 'https://m6r.ai/icons/android-chrome-512x512.png',
        pageType: 'website'
    };

    // Deal with bad links!
    if (path.endsWith(')')) {
        path = path.slice(0, -1);
    }

    if (routes.has(path)) {
        pageInfo = (routes.get(path) as routeDetails);
    }

    const newVNode = pageInfo.render();
    const appElement = document.querySelector('#app');

    // Render the new page.
    updateElement((appElement as HTMLElement), null, null, rootVNode, newVNode);
    rootVNode = newVNode;

    handleMetadata(pageInfo);
    handleOGMetadata(pageInfo);
    handleTwitterMetadata(pageInfo);

    console.log(`Navigated to ${path}`)
}

function scrollPage(x: number, y: number) {
    setTimeout(() => {
        window.scrollTo(x, y);
    }, 50);
}

export function navigateEvent(e: MouseEvent, path: string) {
    e.preventDefault();

    // Update our history to reflect our new position!
    window.history.pushState({scrollPosition: {x: 0, y: 0}}, '', path);
    handleLocation();

    const id = location.hash.substring(1);
    if (id === '') {
        window.scrollTo(0, 0);
        return;
    }

    setTimeout(() => {
        // We have a hash in our URL, so scroll that into view.
        const targetElement = document.getElementById(id);

        if (targetElement) {
            const yPos = targetElement.getBoundingClientRect().top + window.scrollY;
            window.scrollTo(0, yPos);
        }
    }, 50);
}

/**
 * Creates a debounced function that delays the invocation of the provided function
 * until after the specified wait time has elapsed since the last time the debounced
 * function was invoked. This can be useful for limiting the rate at which a function
 * is executed, such as in response to user input or other events.
 *
 * @template T - The type of the function to debounce.
 * @param {T} func - The function to debounce.
 * @param {number} wait - The number of milliseconds to wait before invoking the function
 *                        after the last invocation.
 * @returns {(...args: Parameters<T>) => void} - Returns the debounced version of the provided function.
 */
function debounce<T extends (...args: any[]) => void>(func: T, wait: number): (...args: Parameters<T>) => void {
    let timeout: ReturnType<typeof setTimeout>;

    return function(this: any, ...args: Parameters<T>) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
    };
}

/**
 * Called when the page is first loaded.
 *
 * @param event - the DOMContentLoaded event.
 */
function onDOMContentLoaded(event: Event): void {
    // Add all blog content to the router.
    const blogRoutes = getBlogRoutes();
    blogRoutes.forEach((value, key) => {
        routes.set(key, value);
    });

    // Set scrollRestoration to manual.
    if ('scrollRestoration' in history) {
        history.scrollRestoration = 'manual';
    }

    // Set up the navigation for stepping backward or forward.
    window.onpopstate = (e) => {
        let scrollX: number = 0;
        let scrollY: number = 0;

        if (e.state) {
            const scrollPosition = e.state.scrollPosition;
            scrollX = scrollPosition.x;
            scrollY = scrollPosition.y;
        }

        handleLocation();
        scrollPage(scrollX, scrollY);
    };

    // Set up listener for scroll events.  When the user scrolls we want to updat the scroll positions in the history
    // so forward and backward operations behave intuitively.  Note we debounce this to prevent spamming the history state.
    window.onscroll = debounce(() => {
        window.history.replaceState({scrollPosition: {x: window.scrollX, y: window.scrollY}}, '', window.location.href);
    }, 50);

    // Look to see if our app HTML element has children.  If it does then it means we've loaded a pre-rendered version of the
    // HTML, in which case we want to remove this content so the VDOM takes control properly.
    const appElement = document.querySelector('#app');
    if (appElement?.firstChild) {
        console.log(`Page was already pre-rendered: ${window.location.pathname}`);
        while (appElement.firstChild) {
            appElement.removeChild(appElement.firstChild);
        }
    }

    handleLocation();
    scrollPage(0, 0);

    // Update our history to record our current scroll position.
    window.history.replaceState({scrollPosition: {x: 0, y: 0}}, '', window.location.href);
}

document.addEventListener('DOMContentLoaded', onDOMContentLoaded);
