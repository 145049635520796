import {svg, VNode} from '../lib/dvdi';

export function closeIcon(): VNode {
    return svg('svg', {
            xmlns: 'http://www.w3.org/2000/svg',
            width: 24,
            height: 24,
            viewBox: '0 0 24 24',
            fill: 'none',
            stroke: 'currentColor',
            'stroke-width': 2,
            'stroke-linecap': 'round',
            'stroke-linejoin': 'round'
        },
        svg('line', {x1: 18, y1: 6, x2: 6, y2: 18}),
        svg('line', {x1: 6, y1: 6, x2: 18, y2: 18})
    );
}
