import {h, VNode} from '../lib/dvdi';
import {discordIcon} from './discordIcon';
import {gitHubIcon} from './gitHubIcon';
import {linkedInIcon} from './linkedInIcon';
import {mailIcon} from './mailIcon';
import {xIcon} from './xIcon';
import {youTubeIcon} from './youTubeIcon';

export function pageFooter(): VNode {
    return h('footer', {className: 'footer'},
        h('div', {className: 'container'},
            h('div', {className: 'copyright'},
                '© 2024, 2025 M6R Ltd.'
            ),
            h('nav', {className: 'social'},
                h('a', {className: 'icon', href: 'https://discord.gg/GZhJ7ZtgwN', target: '_blank', title: 'Discord'},
                    discordIcon()
                ),
                h('a', {className: 'icon', href: 'https://youtube.com/@m6rai', target: '_blank', title: 'YouTube'},
                    youTubeIcon()
                ),
                h('a', {className: 'icon', href: 'https://github.com/m6r-ai', target: '_blank', title: 'GitHub'},
                    gitHubIcon()
                ),
                h('a', {className: 'icon', href: 'https://x.com/m6rai', target: '_blank', title: 'X'},
                    xIcon()
                ),
                h('a', {className: 'icon', href: 'https://linkedin.com/company/m6r', target: '_blank', title: 'LinkedIn'},
                    linkedInIcon()
                ),
                h('a', {
                        className: 'icon',
                        href: 'mailto:hello@m6r.ai?subject=Email\ about\ m6r.ai',
                        title: 'Email'
                    },
                    mailIcon(),
                )
            )
        )
    );
}
